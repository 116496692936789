<template>
  <div class="page1">
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div
        class="searchWrapper"
        @keydown.enter.prevent="
          pageNum = 1;
          searchData();
        "
      >
        <el-form :inline="true" label-position="right" class="demo-form-inline">
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="商户ID:">
                <el-input
                  placeholder="请输入商户ID"
                  oninput="value=value.replace(/[^\d.]/g,'')"
                  v-model="formInline.operationId"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="formInline.selectContent"
                  placeholder="请输入准确信息"
                  style="margin-top: 4px"
                >
                  <el-select
                    slot="prepend"
                    v-model="formInline.selectType"
                    style="width: 120px; position: relative; top: -1px; left: -1px"
                  >
                    <el-option :label="$t('searchModule.Merchant_Name')" :value="1" />
                    <el-option :label="$t('searchModule.Merchant_code')" :value="2" />
                    <el-option label="公司名称" :value="3" />
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item label="商户类型:">
                <el-select v-model="formInline.operationTypes" multiple collapse-tags>
                  <el-option label="普通" :value="1"></el-option>
                  <el-option label="平台" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="经营业务类型:">
                <el-select v-model="formInline.businessType">
                  <el-option label="停车业务" :value="1"></el-option>
                  <el-option label="加油业务" :value="2"></el-option>
                  <el-option label="车务业务" :value="3"></el-option>
                  <el-option label="充电业务" :value="4"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="状态:">
                <el-select v-model="formInline.operationStates" multiple collapse-tags>
                  <el-option label="已启用" :value="1"></el-option>
                  <el-option label="已停用" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="最后修改时间:">
                <el-date-picker
                  v-model="timeArray"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >查询
              </AuthorityComponent>
              <el-button icon="el-icon-delete" @click="reset">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                ComponentName="el-button"
                :permission="['button.create']"
                type="primary"
                @click="$router.push({ path: '/MerchantManagerAE' })"
                >创建商户
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%; font-size: 10px">
          <el-table-column :label="$t('searchModule.Merchant_ID')" align="center" prop="operationId" />
          <el-table-column :label="$t('searchModule.Merchant_code')" align="center" prop="operationCode" />
          <el-table-column :label="$t('searchModule.Merchant_Name')" align="center" prop="operationName" />
          <el-table-column :label="$t('searchModule.Merchant_type')" align="center" prop="operationTypeName" />
          <el-table-column :label="$t('searchModule.Business_type_of_operation')" align="center" prop="businessTypeName" />
          <el-table-column label="公司名称" align="center" prop="operationFullName" />
          <el-table-column :label="$t('searchModule.state')" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.operationState === 1" style="color: #67c23a">已启用</span>
              <span v-if="scope.row.operationState === 2" style="color: red">已停用</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.last_Operator')" align="center" prop="operatorName" />
          <el-table-column :label="$t('searchModule.last_Change_time')" align="center" prop="updatedTime" sortable />
          <el-table-column
            label="操作"
            width="120"
            align="center"
            v-if="
              $route.meta.authority.button.invoiceSetting ||
              $route.meta.authority.button.edit ||
              $route.meta.authority.button.manager
            "
          >
            <template slot-scope="scope">
              <el-dropdown
                style="color: #20a0ff; cursor: pointer"
                @command="handleCommand($event, scope.row)"
              >
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.detail']"
                    :command="1"
                    >详情
                  </AuthorityComponent>
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.enable']"
                    v-if="scope.row.operationState !== 1"
                    :command="2"
                    >启用
                  </AuthorityComponent>
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.disable']"
                    v-if="scope.row.operationState === 1"
                    :command="3"
                    >停用
                  </AuthorityComponent>
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.edit']"
                    v-if="scope.row.operationState !== 1"
                    :command="4"
                    >编辑
                  </AuthorityComponent>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as utils from "@/common/js/utils";
import { dateFormat, setIndex } from "@/common/js/public.js";
export default {
  name: "operatorInfoManage",
  data() {
    return {
      total: 0,
      pageSize: 10,
      pageNum: 1,
      loading: false,
      tableData: [],
      formInline: { selectType: 1 },
      timeArray: [],
    };
  },
  methods: {
    // 分页处理
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    showDetail(index, obj) {
      this.$router.push({
        path: "/merchantManagerAE",
        query: obj,
      });
    },
    // 操作细则
    handleCommand(command, row) {
      switch (command) {
        case 1:
          this.$router.push({
            path: "/operatorManageDetail",
            query: { operationId: row.operationId },
          });
          break;
        case 2:
          this.updateState(row.operationId, 1);
          break;
        case 3:
          this.updateState(row.operationId, 2);
          break;
        case 4:
          this.$router.push({
            path: "/merchantManagerAE",
            query: { operationId: row.operationId },
          });
          break;
        default:
          break;
      }
    },
    // 修改状态
    updateState(operationId, operationState) {
      this.loading = true;
      this.tableData = [];
      const tempData = { operationId, operationState };
      const opt = {
        url: "/acb/2.0/operation/updateState",
        method: "post",
        data: tempData,
        success: (res) => {
          this.searchData();
        },
        fail: (res) => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    // 管理用户
    // managerUser(index, obj) {
    //   this.$router.push({
    //     path: "/operatorAdministratorsManage",
    //     query: obj
    //   });
    // },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      const tempData = {
        page: this.pageNum,
        pageSize: this.pageSize,
      };
      const {
        operationId,
        selectType,
        selectContent,
        operationTypes,
        businessType,
        operationStates,
      } = this.formInline;
      if (operationId) {
        tempData.operationId = operationId;
      }
      if (selectContent) {
        switch (selectType) {
          case 1:
            tempData.operationName = selectContent;
            break;
          case 2:
            tempData.operationCode = selectContent;
            break;
          case 3:
            tempData.operationFullName = selectContent;
            break;
          default:
            break;
        }
      }
      if (operationTypes && operationTypes.length > 0) {
        tempData.operationTypes = operationTypes.join(",");
      }
      if (businessType) {
        tempData.businessType = businessType;
      }
      if (operationStates && operationStates.length > 0) {
        tempData.operationStates = operationStates.join(",");
      }
      if (this.timeArray && this.timeArray.length > 0) {
        tempData.startTime = this.$moment(this.timeArray[0]).format("YYYY-MM-DD") + " 00:00:00";
        tempData.endTime = this.$moment(this.timeArray[1]).format("YYYY-MM-DD") + " 23:59:59";
      }
      const opt = {
        url: "/acb/2.0/operation/list",
        method: "get",
        data: tempData,
        success: (res) => {
          console.log("res-->", res);
          this.tableData = setIndex(this.pageNum, res.value.list);
          this.total = res.value.total * 1 || 0;
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    /* 商户名称的下拉框 */
    // getRoleList() {
    //   this.$axios.get("/acb/2.0/operation/nameList").then(res => {
    //     this.loading = false;
    //     if (res.state == 0) {
    //       this.roleList = res.value;
    //     } else {
    //       this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //         confirmButtonText: this.$t('pop_up.Determine')
    //       });
    //     }
    //   });
    // },
    // 重置表单
    reset() {
      this.formInline = { selectType: 1 };
      this.timeReset();
      this.searchData();
    },
    // 时间重置
    timeReset() {
      const startTime = this.$moment().subtract(1, "years").format("YYYY-MM-DD");
      const endTime = this.$moment().format("YYYY-MM-DD");
      this.timeArray = [startTime, endTime];
    },
  },
  // beforeDestroy() {
  //   sessionStorage.searchData = JSON.stringify(this.formInline);
  //   sessionStorage.searchFlag = 1;
  // },
  activated() {
    this.searchData();
  },
  created() {
    // if (sessionStorage.searchFlag == 1) {
    //   this.formInline = JSON.parse(sessionStorage.searchData);
    // }
    this.timeReset();
    this.searchData();
    // this.getRoleList();
    // let xxx = utils.portalHasPage("/operatorAdministratorsManage");
    // this.buttonX = xxx;
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
